import { required } from 'vuelidate/lib/validators'
import { guidValidator } from '@/components/validators'

export default {
  provide: function() {
    return {
      $vv: this.$v
    }
  },
  validations: {
    loadOptions: {
      quoteId: {
        validGuid: guidValidator
      },
      insurerId: {
        required,
        validGuid: guidValidator
      },
      invoiceDate: {
        required
      },
      customerId: {
        validGuid: guidValidator
      }
    }
  }
}
