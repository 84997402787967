<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-new="$userInfo.isSupportUser"
      :show-print="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <pnet-message-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <div class="tabs is-toggle is-toggle-rounded">
              <ul>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.UnRead }">
                  <a @click="tabClicked(messageStatusTypes.UnRead)">
                    <span class="icon is-small">
                      <i class="mdi mdi-email mdi-18px" />
                    </span>
                    <span>
                      <span :class="unreadOffset">Unread</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.UnRead ? 'has-badge-info' : 'has-badge-dark' ]"
                        :data-badge="getCount(messageStatusTypes.UnRead)" />
                    </span>
                  </a>
                </li>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.Read }">
                  <a @click="tabClicked(messageStatusTypes.Read)">
                    <span class="icon is-small">
                      <i class="mdi mdi-email-open mdi-18px" />
                    </span>
                    <span>
                      <span :class="readOffset">Read</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.Read ? 'has-badge-info' : 'has-badge-dark' ]" />
                    </span>
                  </a>
                </li>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.ToSend }">
                  <a @click="tabClicked(messageStatusTypes.ToSend)">
                    <span class="icon is-small">
                      <i class="mdi mdi-send mdi-18px" />
                    </span>
                    <span>
                      <span :class="toSendOffset">To Send</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.ToSend ? 'has-badge-info' : 'has-badge-dark' ]"
                        :data-badge="getCount(messageStatusTypes.ToSend)" />
                    </span>
                  </a>
                </li>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.Sent }">
                  <a @click="tabClicked(messageStatusTypes.Sent)">
                    <span class="icon is-small">
                      <i class="mdi mdi-checkbox-marked-circle mdi-18px" />
                    </span>
                    <span>
                      <span :class="sentOffset">Sent</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.Sent ? 'has-badge-info' : 'has-badge-dark' ]" />
                    </span>
                  </a>
                </li>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.Deleted }">
                  <a @click="tabClicked(messageStatusTypes.Deleted)">
                    <span class="icon is-small">
                      <i class="mdi mdi-delete mdi-18px" />
                    </span>
                    <span>
                      <span :class="deletedOffset">Deleted</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.Deleted ? 'has-badge-info' : 'has-badge-dark' ]" />
                    </span>
                  </a>
                </li>
              </ul>
              <div class="tags has-addons"
                style="margin-left: auto">
                <span class="tag is-dark">Last fetched</span>
                <span v-if="!isTableLoading"
                  class="tag is-success">{{ $filters.formatDateTimeLocale(new Date(), $userInfo.locale) }}</span>
              </div>
            </div>
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(entity, index) in entitySummaries"
                :key="entity.messageId"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  <router-link v-if="canEditMessage"
                    :to="{ path: entity.messageId }"
                    append>
                    {{ entity.messageType }}
                  </router-link>
                  <span v-else>{{ entity.messageType }}</span>
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.regoNo }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.claimNo }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.customerName }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.quoteRequestNo }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.uid }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.quoteNo }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.vehicleDescription }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ $filters.formatDateTimeLocale(entity.createdDate, $userInfo.locale) }}
                </td>
                <td class="is-content-width">
                  <div class="has-text-centered">
                    <router-link v-if="canEditMessage"
                      :to="{ path: entity.messageId }"
                      class="button is-primary is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="View/Edit XML"
                      append>
                      <span class="icon is-medium">
                        <i class="mdi mdi-lead-pencil mdi-24px" />
                      </span>
                    </router-link>
                    <a v-if="canLoadMessage"
                      class="button is-success is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Load Message"
                      @click="load(index)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-download mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canMarkMessageRead"
                      class="button is-info is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Mark as Read"
                      @click="showMessageActionConfirmModal(entity.messageId, messageStatusTypes.Read)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-email-open mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canPrintMessage(entity.messageTypeId)"
                      class="button is-black is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Print Message"
                      @click="print(entity.messageId, entity.messageTypeId)">
                      <span class="icon is-medium ">
                        <i class="mdi mdi-printer mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canMarkMessageUnread"
                      class="button is-info is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Mark as Unread"
                      @click="markMessageAsUnread(entity.messageId)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-email mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canResendMessage(entity.statusId)"
                      class="button is-link is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Resend Message"
                      @click="markMessageAsToSend(entity.messageId)">>
                      <span class="icon is-medium">
                        <i class="mdi mdi-send mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canDeleteMessage"
                      class="button is-danger is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Delete Message"
                      @click="showMessageActionConfirmModal(entity.messageId, messageStatusTypes.Deleted)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-delete mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canUndeleteMessage"
                      class="button is-danger is-small is-inverted tooltip is-tooltip-topright"
                      :data-tooltip="[entity.messageDirection === 0 ? 'Restore to Read' : 'Restore to Sent']"
                      @click="unDeleteMessage(entity.messageId, entity.messageDirection)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-delete-restore mdi-24px" />
                      </span>
                    </a>
                  </div>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
    <load-quote-modal :active.sync="isLoadQuoteModalActive"
      @close="closeModal()"
      @previous="previous()"
      @next="next()"
      @load="loadMessage()"
      :show-icon="false"
      :previous-disabled="currentStep === steps.CreateAssign.id"
      :next-disabled="currentStep === lastStep.id || !enableLoadQuoteNext"
      :continue-disabled="!enableContinue">
      <p slot="text-title">PNET Load Quote</p>
      <div slot="text-content">
        <load-quote-steps v-model="loadOptions"
          :current-step="currentStep"
          :steps="steps"
          ecom-type="pnet" />
      </div>
    </load-quote-modal>
    <confirm-modal :active.sync="isConfirmModalActive"
      @ok="gotoQuote(true)"
      @cancel="gotoQuote(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Go to Quote</p>
      <p slot="text-content">
        <span v-if="savedLoadOptions && savedLoadOptions.isCreateQuote && savedLoadOptions.messageTypeId === messageTypes.QREQ">Quote created</span>
        <span v-else-if="savedLoadOptions && !savedLoadOptions.isCreateQuote && savedLoadOptions.messageTypeId === messageTypes.QREQ">Quote assigned</span>
        <span v-else>Message loaded</span>
        successfully. Go to quote
        <span class="has-text-primary has-text-weight-bold">{{ `${quoteNo}` }}</span> now?
      </p>
    </confirm-modal>
    <confirm-modal :active.sync="isMessageOperationConfirmModalActive"
      @ok="changeMessageStatus(selectedMessageId, messageOperationTypeId)"
      @cancel="isMessageOperationConfirmModalActive = false"
      ok-text="Yes">
      <p slot="text-title">PNET Message</p>
      <div slot="text-content">
        <span>Message will be moved to</span>
        <span class="has-text-weight-bold">
          {{ getFolderName(messageOperationTypeId) }}.
        </span><br>
        <span>Are you sure?</span>
      </div>
    </confirm-modal>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import { AppHeaderButtonTypes } from '@/enums'
import PnetMessageListSideMenu from './PnetMessageListSideMenu'
import PnetMessageRoutes from './route-types'
import BulmaTable from '@/components/BulmaTable'
import PnetMessageService from './PnetMessageService'
import { Columns } from './columns'
import { MessageTypes, MessageStatusTypes, RemarkTypes } from '@/enums'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import StoreMixin from './storeMixin'
import { LoadQuoteModal, LoadQuoteSteps, CustomerOptionTypes, Steps } from '@/components/LoadQuoteSteps'
import { ConfirmModal } from '@/components/BulmaModal'
import PnetMessageValidation from './PnetMessageValidation'
import Guid from '@/components/Guid'
import _cloneDeep from 'lodash/cloneDeep'
import NavService from '@/components/NavService'
import QuoteRoutes from '@/views/quote/route-types'
import StoreUtil from '@/store/utils'
import { Emailer } from '@/classes'
import { KeyValuePairModel } from '@/classes/viewmodels'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { EventHubTypes } from '../../enums'

export default {
  name: 'PnetMessageListView',
  components: {
    AppDetailHeader,
    PnetMessageListSideMenu,
    BulmaTable,
    LoadQuoteModal,
    LoadQuoteSteps,
    ConfirmModal
  },
  mixins: [PnetMessageValidation, DateTimeFiltersMixin, StoreMixin],
  props: {
    value: null
  },
  data() {
    return {
      isTableLoading: false,
      entitySummaries: {},
      countSummaries: null,
      totalRows: 0,
      filter: {
        quoteNo: '',
        rego: '',
        claimNo: '',
        messageTypeId: 0,
        messageStatusId: MessageStatusTypes.UnRead,
        sortColumn: Columns[Columns.length - 2].name,
        sortOrder: Columns[Columns.length - 2].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      },
      selectedRow: null,
      isLoadQuoteModalActive: false,
      currentStep: Steps.CreateAssign.id,
      loadOptions: {
        messageId: Guid.empty(),
        messageTypeId: MessageTypes.QREQ,
        quoteId: Guid.empty(),
        insurerId: Guid.empty(),
        isCreateQuote: true,
        customerId: Guid.empty(),
        customerOption: CustomerOptionTypes.Create,
        title: '',
        customerName: '',
        firstname: '',
        lastname: '',
        isPerson: false,
        vehicleId: Guid.empty(),
        isOverwriteVehicle: false,
        isOverwriteOrmMessageNo: false,
        quotingMethod: ''
      },
      vehicleExists: false,
      steps: Steps,
      isConfirmModalActive: false,
      isMessageOperationConfirmModalActive: false,
      messageOperationTypeId: '',
      quoteNo: '',
      quoteId: Guid.empty(),
      expectedOrmMessageNo: 0,
      selectedMessage: null,
      savedLoadOptions: null,
      unreadOffset: 'has-badge-child-offset2',
      readOffset: 'has-badge-child-offset2',
      toSendOffset: 'has-badge-child-offset2',
      sentOffset: 'has-badge-child-offset2',
      deletedOffset: 'has-badge-child-offset2',
      enableLoadQuoteNext: true
    }
  },
  computed: {
    columns() {
      return Columns
    },
    messageStatusTypes() {
      return MessageStatusTypes
    },
    messageTypes() {
      return MessageTypes
    },
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    canEditMessage() {
      return this.$user.info.isSupportUser
    },
    canLoadMessage() {
      return this.filter.messageStatusId === MessageStatusTypes.UnRead && !this.$licenceExpired
    },
    canMarkMessageRead() {
      return this.filter.messageStatusId === MessageStatusTypes.UnRead
    },
    canMarkMessageUnread() {
      return this.filter.messageStatusId === MessageStatusTypes.Read && this.$user.info.isSupportUser
    },
    canDeleteMessage() {
      if (this.filter.messageStatusId === MessageStatusTypes.ToSend)
      {
        return this.filter.messageStatusId !== MessageStatusTypes.Deleted
      }
      else
      {
        return this.$userInfo.isSupportUser && this.filter.messageStatusId !== MessageStatusTypes.Deleted
      }
    },
    canUndeleteMessage() {
      return this.filter.messageStatusId === MessageStatusTypes.Deleted && this.$user.info.isSupportUser
    },
    lastStep() {
      if (this.steps.OverwriteVehicle.enabled) {
        return this.steps.OverwriteVehicle
      } else {
        return this.steps.UpdateCustomer
      }
    },
    enableContinue() {
      return this.lastStep.id === this.currentStep
    }
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.LoadQuoteNextStatus, (enableLoadQuoteNext)=>{
      this.enableLoadQuoteNext = enableLoadQuoteNext
    })
  },
  created() {
    this.getEntitySummaries()
    this.getCountSummaries()
  },
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.addNew()
          break
        case AppHeaderButtonTypes.Print:
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    filterList() {
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getEntitySummaries()
    },
    resetList() {
      this.filter.quoteNo = ''
      this.filter.rego = ''
      this.filter.claimNo = ''
      this.filter.messageTypeId = 0
      this.filter.sortColumn = Columns[Columns.length - 2].name
      this.filter.sortOrder = Columns[Columns.length - 2].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.persistFilter()
      this.getEntitySummaries()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getEntitySummaries()
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getEntitySummaries()
    },
    tabClicked(messageSatusId) {
      if (this.filter.messageStatusId !== messageSatusId) {
        this.filter.messageStatusId = messageSatusId
        this.filter.pageIndex = 1
        this.selectedRow = null
        this.persistFilter()
        this.getEntitySummaries()
        this.getCountSummaries()
      }
    },
    async getEntitySummaries() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entitySummaries = await PnetMessageService.getEntitySummaries(this.filter)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
    },
    async getCountSummaries() {
      this.countSummaries = await PnetMessageService.getCountSummaries()
    },
    getCount(messageStatusId) {
      if (this.countSummaries) {
        let count = 0
        const summary = this.countSummaries.find(c => c.statusId === messageStatusId)
        if (messageStatusId === MessageStatusTypes.ToSend) {
          const failedSummary = this.countSummaries.find(c => c.statusId === MessageStatusTypes.Failed)
          count = summary.messageCount + failedSummary.messageCount
        } else {
          count = summary.messageCount
        }
        this.setBadgeOffset(messageStatusId, count)
        return count
      }
    },
    setBadgeOffset(messageStatusId, count) {
      let offset = 'has-badge-child-offset2'
      if (count >= 10 && count <= 99) {
        offset = 'has-badge-child-offset3'
      } else if (count >= 100 && count <= 999) {
        offset = 'has-badge-child-offset4'
      } else if (count >= 1000 && count <= 9999) {
        offset = 'has-badge-child-offset5'
      } else if (count >= 10000) {
        offset = 'has-badge-child-offset6'
      }
      switch (messageStatusId) {
        case MessageStatusTypes.UnRead:
          this.unreadOffset = offset
          break
        case MessageStatusTypes.Read:
          this.readOffset = offset
          break
        case MessageStatusTypes.ToSend:
          this.toSendOffset = offset
          break
        case MessageStatusTypes.Sent:
          this.sentOffset = offset
          break
        case MessageStatusTypes.Deleted:
          this.deletedOffset = offset
          break
      }
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    canPrintMessage(messageTypeId) {
      const canPrint =
        messageTypeId === MessageTypes.QREQ || messageTypeId === MessageTypes.AUQT || messageTypeId === MessageTypes.ARES || messageTypeId === MessageTypes.ADQT
      return canPrint && this.filter.messageStatusId === MessageStatusTypes.Read
    },
    canResendMessage(messageStatusId) {
      const canResend =
        this.filter.messageStatusId === MessageStatusTypes.Sent ||
        (this.filter.messageStatusId === MessageStatusTypes.ToSend && messageStatusId === MessageStatusTypes.Failed)
      return canResend
    },
    async updateMessageStatus(messageId, messageStatusId) {
      try {
        await PnetMessageService.updateMessageStatus(messageId, messageStatusId)
      } catch (e) {
        this.$notification.openMessageXhrError('PNET Message', e)
      }
      this.getEntitySummaries()
      this.getCountSummaries()
    },
    markMessageAsRead(messageId) {
      this.updateMessageStatus(messageId, MessageStatusTypes.Read)
    },
    markMessageAsUnread(messageId) {
      this.updateMessageStatus(messageId, MessageStatusTypes.UnRead)
    },
    markMessageAsToSend(messageId) {
      this.updateMessageStatus(messageId, MessageStatusTypes.ToSend)
    },
    markMessageAsDeleted(messageId) {
      this.updateMessageStatus(messageId, MessageStatusTypes.Deleted)
    },
    unDeleteMessage(messageId, messageDirection) {
      if (messageDirection === 0) {
        // Incoming messages
        this.updateMessageStatus(messageId, MessageStatusTypes.Read)
      } else {
        // Outgoing messages
        this.updateMessageStatus(messageId, MessageStatusTypes.Sent)
      }
    },
    async addNew() {
      this.addStoreItem()
    },
    print(messageId, messageTypeId) {
      let reportName = ''
      let messageType = Object.keys(MessageTypes).find(k => MessageTypes[k] === messageTypeId)
      switch (messageTypeId) {
        case MessageTypes.QREQ:
          reportName = 'rptPnetQREQ'
          break
        case MessageTypes.AUQT:
        case MessageTypes.ADQT:
          reportName = 'rptPnetAUQT'
          break
        case MessageTypes.ARES:
          reportName = 'rptPnetARES'
          break
      }
      if (reportName) {
        const params = {
          CompanyID: this.$userInfo.companyId,
          MessageID: messageId
        }
        const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
        const parameterId = this.$guid.newGuid()
        StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

        const emailer = new Emailer()
        emailer.remark = `PNET ${messageType}`
        emailer.remarkTypeId = RemarkTypes.Remark
        emailer.subject = `PNET ${messageType}`
        emailer.reportName = `PNET_${messageType}`
        StoreUtil.setLocalStorage(emailer.id, 'emailer', emailer)
        const routeData = this.$router.resolve({
          name: PrintPreviewRoutes.PrintPreview.name,
          params: { reportName: reportName },
          query: { parameterId: parameterId, emailerId: emailer.id, isNewTab: true }
        })
        window.open(routeData.href, '_blank')
      }
    },
    previous() {
      this.currentStep--
      if (this.currentStep === this.steps.CreateAssign.id) {
        this.$v.loadOptions.quoteId.$reset()
      } else if (this.currentStep === this.steps.SelectInsurer.id) {
        this.$v.loadOptions.insurerId.$reset()
      } else if (this.currentStep === this.steps.UpdateCustomer.id) {
        this.$v.loadOptions.customerId.$reset()
      }
    },
    next() {
      switch (this.currentStep) {
        case Steps.CreateAssign.id:
          this.$v.loadOptions.quoteId.$touch()
          if (!this.$v.loadOptions.quoteId.$error || this.loadOptions.isCreateQuote) {
            this.currentStep++
          }
          break
        case Steps.SelectInsurer.id:
          this.$v.loadOptions.insurerId.$touch()
          if (!this.$v.loadOptions.insurerId.$error) {
            this.currentStep++
          }
          break
        case Steps.UpdateCustomer.id:
          this.$v.loadOptions.customerId.$touch()
          if (!this.$v.loadOptions.customerId.$error || this.loadOptions.customerOption === CustomerOptionTypes.Create) {
            this.currentStep++
          }
          break
        case Steps.OverwriteVehicle.id:
          break
      }
    },
    closeModal() {
      this.isLoadQuoteModalActive = false
      this.reset()
    },
    async loadMessage() {
      this.$showSpinner('Loading quote')
      try {
        this.isLoadQuoteModalActive = false
        const result = await PnetMessageService.loadMessage(this.loadOptions)
        this.quoteId = result.data
        // console.log(this.quoteId)
        this.getEntitySummaries()
        this.getCountSummaries()
        if (Guid.validGuid(this.quoteId)) {
          this.quoteNo = await PnetMessageService.getQuoteNo(this.quoteId)
          this.print(this.loadOptions.messageId, this.loadOptions.messageTypeId)
          if (this.loadOptions.isCreateQuote && this.loadOptions.messageTypeId === MessageTypes.QREQ) {
            this.createAuditTrail(this.quoteId, 'insert', '')
          } else {
            // TODO: create update AuditTrail entry
          }
          this.savedLoadOptions = _cloneDeep(this.loadOptions)
          this.isConfirmModalActive = true
        }
      } catch (e) {
        throw e
      } finally {
        this.reset()
        this.$hideSpinner()
      }
    },
    reset() {
      this.loadOptions.messageId = Guid.empty()
      this.loadOptions.messageTypeId = MessageTypes.QREQ
      this.loadOptions.quoteId = Guid.empty()
      this.loadOptions.isCreateQuote = true
      this.loadOptions.insurerId = Guid.empty()
      this.loadOptions.customerOption = CustomerOptionTypes.Create
      this.loadOptions.isOverwriteVehicle = false
      this.loadOptions.customerId = Guid.empty()
      this.loadOptions.title = ''
      this.loadOptions.customerName = ''
      this.loadOptions.firstname = ''
      this.loadOptions.lastname = ''
      this.loadOptions.isPerson = false
      this.loadOptions.vehicleId = Guid.empty()
      this.loadOptions.isOverwriteOrmMessageNo = false
      this.loadOptions.quotingMethod = ''
      this.currentStep = Steps.CreateAssign.id
      this.selectedMessage = null
      // Reset validation objects
      this.$v.loadOptions.$reset()
    },
    async load(index) {
      this.selectedMessage = this.entitySummaries[index]
      this.loadOptions.messageId = this.selectedMessage.messageId
      this.loadOptions.messageTypeId = this.selectedMessage.messageTypeId
      if (this.selectedMessage.messageTypeId === MessageTypes.QREQ || this.selectedMessage.messageTypeId === MessageTypes.MANQ) {
        if (this.selectedMessage.messageTypeId === MessageTypes.MANQ && !this.selectedMessage.customerName) {
          this.$notification.openNotificationWithType('danger', 'PNET', 'Cannot find matching QREQ message. Load cancelled.\nPlease contact FlexiQuote support.')
          return
        }
        this.assertVehicleExists(this.selectedMessage.regoNo)
        this.loadOptions.isPerson = this.selectedMessage.isPerson
        this.loadOptions.customerName = this.selectedMessage.customerName
        if (this.selectedMessage.isPerson) {
          const names = this.selectedMessage.customerName.split(', ')
          this.loadOptions.lastname = names[0]
          this.loadOptions.firstname = names.length > 1 ? names[1] : ''
          this.loadOptions.title = this.selectedMessage.title
        }
        this.isLoadQuoteModalActive = true
      } else {
        this.loadOptions.quoteNo = this.selectedMessage.quoteNo
        this.loadMessage()
      }
    },
    async assertVehicleExists(rego) {
      this.vehicleExists = await PnetMessageService.assertVehicleExists(rego)
      this.steps.OverwriteVehicle.enabled = this.vehicleExists
    },
    async gotoQuote(goto) {
      this.isConfirmModalActive = false
      if (goto) {
        const accessModel = await NavService.getAccess('A077', 'detail')
        let url = ''
        if (accessModel.version === '2') {
          const routeData = this.$router.resolve({
            name: QuoteRoutes.QuoteDetail.name,
            params: { quoteId: this.quoteId }
          })
          url = routeData.href
        } else {
          url = `${process.env.VUE_APP_ROOT_URI}/modules/handlers/QuoteLoading.ashx?quoteId=${this.quoteId}&mode=5`
        }
        window.open(url, '_blank')
      }
    },
    async createAuditTrail(entityId, operationType, deepdiff) {
      const quote = await PnetMessageService.getQuoteEntity(entityId)
      const audtiTrailItem = {
        auditTrailId: null,
        performedAt: new Date().toISOString,
        userId: this.$userInfo.userId,
        userName: this.$userInfo.displayName,
        userRole: this.$userInfo.isSupportUser ? 'Support' : 'Normal',
        userAgent: '',
        userHostAddress: '',
        userHostName: '',
        url: '',
        companyId: this.$userInfo.companyId,
        entityId: entityId,
        entityType: 'Quote',
        entityReference: `${quote.prefix}${quote.quoteNo}${quote.suffix}`,
        operationType: operationType,
        deepdiff: operationType === 'update' ? JSON.stringify(deepdiff) : null,
        entity: operationType === 'insert' ? JSON.stringify(quote) : null
      }
      PnetMessageService.postAuditTrailItem(audtiTrailItem)
    },
    showMessageActionConfirmModal(messageId, messageStatusTypeId) {
      this.messageOperationTypeId = messageStatusTypeId
      this.selectedMessageId = messageId
      this.isMessageOperationConfirmModalActive = true
    },
    changeMessageStatus(messageId, messageStatusTypeId) {
      switch (messageStatusTypeId) {
        case MessageStatusTypes.Read:
          this.updateMessageStatus(messageId, MessageStatusTypes.Read)
          break
        case MessageStatusTypes.Unread:
          this.updateMessageStatus(messageId, MessageStatusTypes.UnRead)
          break
        case MessageStatusTypes.ToSend:
          this.updateMessageStatus(messageId, MessageStatusTypes.ToSend)
          break
        case MessageStatusTypes.Deleted:
          this.updateMessageStatus(messageId, MessageStatusTypes.Deleted)
          break
      }
      this.isMessageOperationConfirmModalActive = false
    },
    getFolderName(messageStatusTypeId) {
      switch (messageStatusTypeId) {
        case MessageStatusTypes.Read:
          return 'Read'
          break
        case MessageStatusTypes.Unread:
          return 'Unread'
          break
        case MessageStatusTypes.ToSend:
          return 'To Send'
          break
        case MessageStatusTypes.Deleted:
          return 'Deleted'
          break
      }
    }
  },
  beforeRouteLeave: function(to, from, next) {
    if (to.name === PnetMessageRoutes.PnetMessageView.name) {
      const messageId = to.params.messageId
      const message = this.entitySummaries.find(m => m.messageId === messageId)
      this.setMessageSummary(message)
    }
    next()
  }
}
</script>
