const LoadQuoteModal = r => require.ensure([], () => r(require('./LoadQuoteModal.vue')), 'loadquotesteps')
const LoadQuoteSteps = r => require.ensure([], () => r(require('./LoadQuoteSteps.vue')), 'loadquotesteps')

const CustomerOptionTypes = {
  Create: 'create',
  Overwrite: 'overwrite',
  None: 'none'
}

const Steps = {
  CreateAssign: { id: 1, enabled: true },
  SelectInsurer: { id: 2, enabled: true },
  UpdateCustomer: { id: 3, enabled: true },
  OverwriteVehicle: { id: 4, enabled: true },
  GotoQuote: { id: 5, enabled: true }
}

export { LoadQuoteModal, LoadQuoteSteps, CustomerOptionTypes, Steps }
